import React, {useState} from "react"
import parse from 'html-react-parser';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_video-wrap--outer.scss";


const BasicFullVideo = (data, {isInView}) => {
  
  let classList = [
    'video-wrap--outer', 
  ]; 

  const removeBottomMargin = data.removeBottomMargin;  


  if(removeBottomMargin === true){
    classList.push('no-margin');
  }
    
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
      
      <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} data-bg-shape="show_shape">
        <div className="wrapper-900">
          <div className="video-wrapper">
            {parse(data.videoEmbedCode)}
          </div>
        </div>
      </section>
      
      }
    </VisibilitySensor>
  )
}

export default BasicFullVideo

